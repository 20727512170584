//查询设备技术参数
import axios from "@/utils/axios";
import apiPath from "@/utils/apiPath";

const equipment = apiPath.equipment;

export const getEquipmentTechParams = params => {
  return axios.get(
    `${equipment}/list/equipment/getTechnicalList/${params.equipmentId}`
  );
};
// 获取设备文档
export const getEquipmentDoc = params => {
  return axios.get(`${equipment}/select/document/equipment/file/list`, {
    params
  });
};
// 获取安全附件
export const getEquipmentSafetyAffix = params => {
  return axios.get(`${equipment}/page/equipment/safety`, { params });
};
// 获取关联辅件
export const getEquipmentLinkAccessory = params => {
  return axios.get(`${equipment}/page/equipment/subsidiary`, { params });
};
// 获取备品备件
export const getEquipmentParts = params => {
  return axios.get(`${equipment}/page/reserve/part/need`, { params });
};
// 获取检验周期
export const getEquipmentOverhaulCycle = params => {
  return axios.get(`${equipment}/page/equipment/cyclecheck`, { params });
};
// 获取润滑保养周期
export const getEquipmentMaintainPlan = params => {
  return axios.get(`${equipment}/page/equipment/cyclelubrication`, { params });
};

// 获取检定周期
export const getEquipmentVerificationPlan = params => {
  return axios.get(`${equipment}/page/equipment/cyclecalibration`, { params });
};

// 获取巡检检测周期
export const getEquipmentInspectionPlan = params => {
  return axios.get(`${equipment}/page/overhaul/plans`, { params });
};

// 获取基础编码表值
export const getBasicParams = code => {
  return axios.get(`${equipment}/list/Base/queryList/${code}`);
};
